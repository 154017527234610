body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.App {
    text-align: center;
    width: 100%;
    height: 100vh;
}
